import * as React from "react"

function InstagramIcon(props) {
    const { color, size, margin } = props
    return (
        <svg width={size ? size + "px" : "40px"} height={size ? size + "px" : "40px"} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
            <g fill={color ? color : "white"}>
                <path d="m1008.8 56.25h-817.5c-75 0-135 60-135 135v819.38c0 75 60 135 135 135h819.38c75 0 135-60 135-135v-819.38c-1.875-75-61.875-135-136.88-135zm-915 483.75h219.38c-3.75 18.75-5.625 39.375-5.625 60s1.875 41.25 5.625 60h-219.38zm249.38 60c0-140.62 114.38-256.88 256.88-256.88s256.88 116.25 256.88 256.88-116.25 256.88-256.88 256.88-256.88-116.25-256.88-256.88zm646.88-97.5h-78.75v-408.75h78.75zm-114.38-5.625c-16.875-45-43.125-84.375-78.75-114.38v-288.75h78.75zm11.25 43.125h219.38v120h-219.38c3.75-18.75 5.625-39.375 5.625-60s0-41.25-5.625-60zm219.38-348.75v313.12h-78.75v-408.75c45 9.375 78.75 48.75 78.75 95.625zm-915-97.5h568.12v260.62c-46.875-30-101.25-46.875-159.38-46.875-127.5 0-236.25 82.5-277.5 196.88h-228.75v-313.12c0-54.375 43.125-97.5 97.5-97.5zm817.5 1012.5h-817.5c-54.375 0-97.5-43.125-97.5-97.5v-311.25h228.75c39.375 114.38 148.12 196.88 277.5 196.88s236.25-82.5 277.5-196.88h228.75v313.12c0 52.5-43.125 95.625-97.5 95.625z" />
                <path d="m600 761.25c88.125 0 161.25-71.25 161.25-161.25s-71.25-161.25-161.25-161.25-161.25 71.25-161.25 161.25 73.125 161.25 161.25 161.25zm0-285c67.5 0 123.75 54.375 123.75 123.75s-54.375 123.75-123.75 123.75-123.75-54.375-123.75-123.75 56.25-123.75 123.75-123.75z" />
                <path d="m236.25 322.5c46.875 0 84.375-37.5 84.375-84.375s-37.5-84.375-84.375-84.375-84.375 37.5-84.375 84.375 37.5 84.375 84.375 84.375zm0-133.12c26.25 0 46.875 20.625 46.875 46.875s-20.625 46.875-46.875 46.875-46.875-20.625-46.875-46.875 20.625-46.875 46.875-46.875z" />
            </g>
        </svg>
    )
}

export default InstagramIcon
