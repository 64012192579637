// src/components/new-footer.js
import React from 'react';

import { Link, Script } from 'gatsby';

// Icons
import LogoWhite from '../../icons/logo-white';
import FacebookIcon from '../../icons/social/facebook';
import InstagramIcon from '../../icons/social/instagram';
import TikTokIcon from '../../icons/social/tiktok';

import '../../styles/footer.scss';
import "../../styles/responsive/footer.scss"

const Footer = () => {
    return (
        <>
            <Script
                async
                data-environment="production"
                src="https://js.klarna.com/web-sdk/v1/klarna.js"
                data-client-id="114bcaa4-16b8-55a9-971c-56d6d295f040"
            />
            <footer className="footer">
                <div className="footerWrapper">
                    <div className="companyInfo">
                        <div className="logo"><LogoWhite /></div>
                        <p><b>SuperHeroCase.com crafts personalized phone cases</b> — Motivating kids and teens to envision and pursue their best future selves.</p>
                        <klarna-placement
                            data-key="footer-promotion-auto-size"
                            data-locale="en-SE"
                        />
                    </div>
                    <div className="footerNav">
                        <h2>SuperHero of Phone Cases</h2>
                        <div className="footerNavWrapper">
                            <nav className="footerNavBox">
                                <ul>
                                    <li className="title">Company</li>
                                    <li><Link to="/our-story/">Our Story</Link></li>
                                    <li><Link to="/our-story/">Our Vision</Link></li>
                                    {/* <li><Link to="/">Sustainability</Link></li> */}
                                </ul>
                            </nav>
                            <nav className="footerNavBox">
                                <ul>
                                    <li className="title">Product</li>
                                    <li><Link to="/create-avatar/">Create avatar</Link></li>
                                    <li><Link to="/#GiftCardBanner">Gift Card</Link></li>
                                    {/* <li><Link to="/#ExploreCases">Phone Cases</Link></li> */}
                                </ul>
                            </nav>
                            <nav className="footerNavBox">
                                <ul>
                                    <li className="title">Support</li>
                                    {/* <li><Link to="/shipping/">Shipping</Link></li> */}
                                    <li><Link to="/#FAQ">FAQ</Link></li>
                                    <li><Link to="/contact-us/">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="lowerFooterWrapper">
                    <div className="socialIcons">
                        <div className="icon"><a href="https://www.facebook.com/superherocase/" target="_blank" rel="noopener" title="Go to Facebook Page"><FacebookIcon /></a></div>
                        <div className="icon"><a href="https://www.instagram.com/superherocaseofficial/" target="_blank" rel="noopener" title="Go to Instagram Page"><InstagramIcon /></a></div>
                        {/* <div className="icon"><TikTokIcon /></div> */}
                    </div>
                    <div className="copyright">
                        {new Date().getFullYear()} © SuperHero Case created in 🇸🇪 by J&J Art AB · All rights reserved · <Link to="/privacy-policy/">Privacy Policy</Link> · <Link to="/cookie-policy/">Cookie Policy</Link> · <Link to="/terms-and-condition/">Terms & Condition</Link>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
