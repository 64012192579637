import React, { createContext, useReducer } from 'react';
import { useShopifyCookies } from '@shopify/hydrogen-react';

// DataLayer
import { localStorageFetch } from '../functions/local-storage';

const initialState = {
  readyPlayerMe: {
    "urlId": false,
    "avatarId": false,
    "userId": false,
  },
  background: {
    type: "image",
    colorId: false,
    imageId: "new-york",
    patternId: false,
    color: false,
    image: "https://firebasestorage.googleapis.com/v0/b/superhero-case-486f5.appspot.com/o/backgrounds%2Fcity%2Fnew-york%2Fnew-york-small.png?alt=media&token=c290255d-15ec-4b01-a420-15d2d8a2e772",
    pattern: false
  },
  qr: {
    status: false,
    phone: "",
    message: "Hello, I found a mobile which I think belongs to you. I got your phone number by scanning the QR on your phone case."
  },
  emotion: "smile",
  brand: false,
  productModel: false
};

const ConfigContext = createContext({ configData: initialState });

export const ConfigProvider = ({ children }) => {
  const [configData, configDataDispatch] = useReducer(configDataReducer, initialState)

  // Use Shopify Cookies
  useShopifyCookies({ hasUserConsent: true })

  // Check for recent Avatar Object
  React.useEffect(() => {
    // Get avatar if set
    const avatarObject = localStorageFetch({ type: "get", infoType: "avatar" })
    if (avatarObject) configDataDispatch({ type: "avatarInformation", value: avatarObject })
  }, [])

  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      const viewport = document.querySelector('meta[name="viewport"]');
      if (viewport) {
        viewport.content = 'initial-scale=1';
        viewport.content = 'width=device-width';
      }
    }, 100);
  }

  return (
    <ConfigContext.Provider
      value={{
        configData,
        configDataDispatch,
        scrollToTop
      }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;

const configDataReducer = (state, action) => {
  switch (action.type) {
    case "toggleQr":
      state.qr.status = action.value
      return { ...state }
    case "changeBackground":
      if (action.bgType === "color") {
        state.background.colorId = action.id
        state.background.color = action.value
        state.background.type = action.bgType
      }
      else if (action.bgType === "image") {
        state.background.imageId = action.id
        state.background.image = action.value
        state.background.type = action.bgType
      }
      else if (action.bgType === "pattern") {
        state.background.patternId = action.id
        state.background.pattern = action.value
      }
      return { ...state }
    case "changeEmotion":
      state.emotion = action.value
      return { ...state }
    case "changeBody":
      state.body = action.value
      return { ...state }
    case "changeBrand":
      state.brand = action.value
      return { ...state }
    case "changeProductModel":
      state.productModel = action.value
      return { ...state }
    case "changeQr":
      state.qr[action.field] = action.value
      return { ...state }
    case "clearReadyPlayerMe":
      state.readyPlayerMe = {
        "img": false,
        "url": false,
        "avatarId": false,
        "userId": false,
      }
      return { ...state }
    case "avatarInformation":
      const object = action.value
      const urlId = object.url.replace("https://models.readyplayer.me/", "").replace(".glb", "")
      state.readyPlayerMe = {
        urlId: urlId,
        avatarId: object.avatarId,
        userId: object.userId,
      }
      return { ...state }
    case "setDesign":
      state = action.value
      return { ...state }
    default:
      return { ...state };
  }
};
