import React from 'react';
import { navigate } from "gatsby";
import { ShopifyProvider, CartProvider, CartLineProvider, ProductProvider, useCart } from '@shopify/hydrogen-react';

const ShopifyProviderWrapper = ({ children }) => {
    return <ShopifyProvider
        storeDomain="https://ead51c-6.myshopify.com"
        storefrontToken="79eb61d69c24130ca379bd479791bdb2"
        storefrontApiVersion="2023-10"
        countryIsoCode="SE"
        languageIsoCode="EN"
    >{children}</ShopifyProvider>
}

const ShopifyCartProviderWrapper = ({ children }) => {
    return <CartProvider
        onCreate={() => console.log('Creating cart...')}
        onCreateComplete={() => navigate("/cart/")}
        onLineAdd={() => console.log('... Line is being added')}
        onLineRemove={() => console.log('Line item removed')}
        onLineAddComplete={() => navigate("/cart/")}
    ><CartWrapper>{children}</CartWrapper></CartProvider>
}

const CartWrapper = ({ children }) => {
    const { lines, status, error, countryCode } = useCart();
    return <>{children}</>
}

const ShopifyCartLineProviderWrapper = ({ line, children }) => {
    return <CartLineProvider
        line={line}
    >{children}</CartLineProvider>
}

const ShopifyProductProviderWrapper = ({ data, initialVariantId, children }) => {
    return <ProductProvider
        data={data}
        initialVariantId={initialVariantId}>
        {children}
    </ProductProvider>
}

export { ShopifyProviderWrapper, ShopifyCartProviderWrapper, ShopifyCartLineProviderWrapper, ShopifyProductProviderWrapper } 