import React from 'react';
import { ShopifyProviderWrapper, ShopifyCartProviderWrapper } from './src/providers/shopify';
import { ConfigProvider } from './src/providers/config';
import { ProductProvider } from './src/providers/products';

// Components
import Footer from './src/components/general/footer';

// Styles
import "./src/styles/global.scss"
import "./src/styles/responsive/global.scss"
import "./src/styles/variables.scss"

export const wrapRootElement = ({ element }) => (
    <>
        <ProductProvider>
            <ConfigProvider>
                <ShopifyProviderWrapper>
                    <ShopifyCartProviderWrapper>
                        {element}
                        <Footer />
                    </ShopifyCartProviderWrapper>
                </ShopifyProviderWrapper>
            </ConfigProvider>
        </ProductProvider>
    </>
);
