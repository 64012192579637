import React, { createContext, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
const ProductContext = createContext({});

const useProductData = (id) => {
  const data = useStaticQuery(graphql`
  query MyQuery {
    allProducts: allShopifyProduct(filter: {productType: {ne: "Showcase"}}) {
      products: nodes {
        id
        shopifyId
        storefrontId
        legacyResourceId
        title
        description
        handle
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
        }
        variants {
          _product
          id
          shopifyId
          storefrontId
          legacyResourceId
          displayName
          title
          price
          sku
          inventoryQuantity
        }
        usps: metafield(key: "unique_selling_points", namespace: "custom") {
          value
        }
      }
    }
  }
    `)
  const productObject = {}
  data.allProducts.products.forEach(product => productObject[product.handle] = product)
  return productObject
}

export const ProductProvider = ({ children }) => {
  const [productState] = useState(useProductData())
  return (
    <ProductContext.Provider value={{ productState }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContext;