function localStorageFetch(type, infoType, value) {
    try {
        // Push the event
        if (type === "set") window.localStorage.setItem(`superherocase-${infoType}`, JSON.stringify(value))
        else if (type === "get") return JSON.parse(window.localStorage.getItem(`superherocase-${infoType}`))
        else if (type === "remove") window.localStorage.removeItem(`superherocase-${infoType}`)
    }
    catch (err) { console.log(err) }
}

function dataLayerPush(eventName, data, eCommerce) {
    try {
        const userId = localStorageFetch("get", "user-id")
        const email = localStorageFetch("get", "email")
        const phone = localStorageFetch("get", "phone")
        const customerObject = {
            user_id: userId ? userId : false,
            email: email ? email : false,
            phone: phone ? phone : false
        }
        // Initiate Data Layer
        window.dataLayer = window.dataLayer || [];
        if (!eventName) throw ("DataLayer — No event name being sent")
        // Clear eCommerce on new eCommerce event
        if (eCommerce) {
            window.dataLayer.push({ ecommerce: null })
            window.dataLayer.push({
                event: eventName,
                ecommerce: { ...data },
                customer: customerObject,
            })
        }
        // Else push the data
        else window.dataLayer.push({
            event: eventName,
            customer: customerObject,
            ...data
        })
    }
    catch (err) { console.log(err) }
}

function cartEcommerceObject(cart) {
    try {
        const items = cart.lineItems.physical_items.map((item, i) => {
            const productName = item.name.split(" - ")[0]
            return {
                item_id: item.product_id,
                item_name: productName.length > 0 ? productName : item.name,
                sku: item.sku,
                affiliation: "Attitude.se",
                index: i + 1,
                // item_category: "Apparel",
                // item_category2: "Adult",
                // item_category3: "Shirts",
                // item_category4: "Crew",
                // item_category5: "Short sleeve",
                item_variant: item.variant_id ? item.variant_id : false,
                price: item.list_price,
                quantity: item.quantity
            }
        })
        return {
            currency: "SEK",
            value: cart.totalAmount,
            items: items
        }
    }
    catch (err) { console.log(err) }
}

function lineItemEcommerceObject(productMod, brands) {
    try {
        const productId = productMod.product.bigcommerce_id
        const productName = productMod.product.name
        const productPrice = productMod.pricePerItem
        const productVariant = productMod.activeColorName ? productMod.activeColorName : productMod.activeVariant
        const sku = productMod.product.sku
        const brand_id = productMod.product.brand_id
        const brand = brands && brand_id !== 0 ? brands.brand.filter(obj => brand_id === obj.bigcommerce_id)[0] : "Attitude.se"
        const itemsArray = []
        // With Sizes
        if (Object.keys(productMod.activeSize).length > 0) Object.keys(productMod.activeSize).forEach(key => {
            const activeSize = productMod.activeSize[key]
            const activeSizeName = activeSize.label
            const quantity = activeSize.quantity
            itemsArray.push({
                item_id: productId,
                item_name: `${productName} - ${activeSizeName}`,
                sku: sku,
                affiliation: "Attitude.se",
                index: itemsArray.length + 1,
                // item_category: "Apparel",
                // item_category2: "Adult",
                // item_category3: "Shirts",
                // item_category4: "Crew",
                // item_category5: "Short sleeve",
                item_variant: productVariant,
                item_brand: brand.name,
                price: productPrice,
                quantity: quantity
            })
        })
        else itemsArray.push({
            item_id: productId,
            item_name: `${productName}`,
            sku: sku,
            affiliation: "Attitude.se",
            index: itemsArray.length + 1,
            // item_category: "Apparel",
            // item_category2: "Adult",
            // item_category3: "Shirts",
            // item_category4: "Crew",
            // item_category5: "Short sleeve",
            item_variant: productVariant,
            item_brand: brand.name,
            price: productPrice,
            quantity: productMod.quantity
        })
        return {
            currency: "SEK",
            value: productMod.totalPrice,
            items: itemsArray
        }
    }
    catch (err) { console.log(err) }
}

function returnItemObject(product, index) {
    try {
        const productId = product.legacyResourceId
        const productName = product.title
        const variantName = product.variant.title
        const variantPrice = product.variant.price
        const variantSku = product.variant.sku
        const variantCategory = product.variant.category
        const variantBrand = product.variant.brand
        const variantModel = product.variant.model
        const qrEnabled = product.qr
        return {
            item_id: productId,
            item_name: `${productName}`,
            sku: variantSku,
            affiliation: "SuperHeroCase.com",
            index: index ? index : 1,
            item_category: variantCategory ? variantCategory : "",
            item_category2: variantName ? variantName : "",
            item_category3: variantBrand ? variantBrand : "",
            item_category4: variantModel ? variantModel : "",
            item_category5: qrEnabled ? qrEnabled : "",
            item_variant: variantName ? variantName : "",
            item_brand: variantBrand ? variantBrand : "",
            price: variantPrice ? variantPrice : "",
            quantity: 1
        }
    }
    catch (err) { console.log(err) }
}

const createEcommerceObject = (items) => {
    let value = 0
    // Create Items Array
    let array = []
    if (items.length) array = [...array, ...items]
    else if (items.length === 0) array = []
    else array.push(items)
    // Calculates Value & set correct index
    array.forEach((item, i) => {
        item.index = (i + 1)
        value += parseInt(item.price)})
    if (value === NaN) value = 0
    return {
        currency: "SEK",
        value: value,
        items: array
    }
};

const createEcommerceItem = (product, brand, model) => {
    const productPrice = product.priceRangeV2.maxVariantPrice.amount
    const productVariant = product.variants.filter(obj => obj.title.toLowerCase().includes(model.toLowerCase()))[0]
    const mainCategory = () => {
        if (product.handle === "screen-protector") return "Screen Protector"
        else if (product.handle === "superhero-gift-card") return "Gift Card"
        else return "Phone Case"
    }
    return {
        item_id: product.handle,
        item_name: product.title,
        sku: productVariant.sku,
        affiliation: "SuperHeroCase.com",
        index: 1,
        item_brand: brand,
        item_category: mainCategory(),
        item_category2: product.handle,
        item_category3: brand ? brand : productVariant.sku,
        item_category4: model,
        item_variant: productVariant.title,
        price: productVariant.price ? productVariant.price : productPrice,
        quantity: 1
    }
};

const createCartEcommerceItem = (product) => {
    const merchandiseObject = product.merchandise
    if (merchandiseObject) {
        const brand = product.attributes.filter(attr => attr.key === "_brand")[0]?.value
        const model = product.attributes.filter(attr => attr.key === "_model")[0]?.value
        const mainCategory = () => {
            if (merchandiseObject.product.handle === "screen-protector") return "Screen Protector"
            else if (merchandiseObject.product.handle.includes("gift-card")) return "Gift Card"
            else return "Phone Case"
        }
        const calculateDiscount = () => {
            if (parseInt(product.cost.totalAmount.amount) !== parseInt(merchandiseObject.price.amount)) return {
                discount: parseInt(merchandiseObject.price.amount) - parseInt(product.cost.totalAmount.amount)
            }
        }
        return {
            item_id: merchandiseObject.product.handle,
            item_name: merchandiseObject.product.title,
            affiliation: "SuperHeroCase.com",
            index: 1,
            item_brand: brand,
            item_category: mainCategory(),
            item_category2: merchandiseObject.product.handle,
            item_category3: brand ? brand : merchandiseObject.title,
            item_category4: model,
            item_variant: merchandiseObject.title ? merchandiseObject.title : null,
            price: product.cost.totalAmount.amount ? parseInt(product.cost.totalAmount.amount) : parseInt(merchandiseObject.price.amount),
            quantity: merchandiseObject.quantity ? merchandiseObject.quantity : 1,
            ...calculateDiscount()
        }
    }
};

export { localStorageFetch, dataLayerPush, cartEcommerceObject, lineItemEcommerceObject, returnItemObject, createEcommerceObject, createEcommerceItem, createCartEcommerceItem }